<template>
  <d-remote-monitoring-indicators-add @on-confirm="onConfirmHandler" />
</template>

<script>

export default {
  name: 'RemoteMonitoringIndicatorsAdd',
  methods: {
    onConfirmHandler() {
      this.$router.push('/remote-monitoring/indicators/');
    },
  },
};
</script>
